// on importe la CSS
import '../scss/app.scss';

// Importer tous les fichiers SVG de assets/svg
const importAll = (r) => r.keys().forEach(r);
importAll(require.context('../svg', false, /\.svg$/));

/* ---------------------------------------------------------
** Importation des scripts
** -------------------------------------------------------*/
// jquery est chargé via le enqueue script de WP
import $ from 'jquery';

// https://www.npmjs.com/package/gsap
import { gsap } from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import imagesLoaded from "imagesloaded";
imagesLoaded.makeJQueryPlugin( $ );

// https://www.npmjs.com/package/slick-carousel
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

// https://www.npmjs.com/package/jquery-plugin-viewport-checker
import viewportChecker from "jquery-viewport-checker";


// https://github.com/somewebmedia/hc-sticky
import hcSticky from "hc-sticky";

// Pas de NPM
import 'jplayer';

/***
 * Cookie - TAC
 */
window.addEventListener('DOMContentLoaded', () => {

    //Initialisation de tarteaucitron
    if (typeof tarteaucitron === 'object') {
        tarteaucitron.init({
            privacyUrl: '' /* Privacy policy url */,
            hashtag:
                '#tarteaucitron' /* Open the panel with this hashtag */,
            cookieName: 'tarteaucitron' /* Cookie name */,
            orientation: 'bottom' /* Banner position (top - bottom) */,
            showAlertSmall: false /* Show the small banner on bottom right */,
            cookieslist: false /* Show the cookie list */,
            showIcon: true /* Show cookie icon to manage cookies */,
            iconPosition:
                'BottomLeft' /* BottomRight, BottomLeft, TopRight and TopLeft */,
            adblocker: false /* Show a Warning if an adblocker is detected */,
            DenyAllCta: true /* Show the deny all button */,
            AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
            highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,
            handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
            removeCredit: true /* Remove credit link */,
            moreInfoLink: false /* Show more info link */,
            useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
            useExternalJs: false /* If false, the tarteaucitron.js file will be loaded */,
            readmoreLink: '' /* Change the default readmore link */,
            mandatory: true /* Show a message about mandatory cookies */,
        });
        // (tarteaucitron.job = tarteaucitron.job || []).push('vimeo');
        (tarteaucitron.job = tarteaucitron.job || []).push('youtube');

        document.getElementById('tarteaucitronManager').addEventListener('click', function (e) {
            e.preventDefault();
        });
    }
});

/* -------------------
** Slick Caroussel des témoignages de l'accueil
** https://github.com/kenwheeler/slick/
** -------------------*/
$("#temoignages").slick({
    arrows: false,
    dots: true,
    adaptiveHeight: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 3000
});

/* -------------------
** Module du megamenu
** -------------------*/
// On active le timer
function setInactiveTimer(element) {
    $(element).data(
        'inactiveTimer',
        setTimeout(function() { fermeMenu(element); }, 800)
    );
}

// On désactive le timer
function clearIntactiveTimer(element) {
    clearTimeout($(element).data('inactiveTimer'));
}

// On ouvre le menu
function ouvreMenu(element) {
    $(element).children('.sub-menu').show().end();
    if($(element).children('.sub-menu').length > 0){
        $(element).addClass('actif--ssmenu');
    }
}

// On ferme le menu
function fermeMenu(element) {
    $(element).children('.sub-menu').hide().end().removeClass('actif--ssmenu');
}

// Cache les sous menu
$('.sub-menu').hide();

// Gestion des rollovers
$('ul.megamenu > li').mouseenter(function() {
    $('ul.megamenu li').not(this).children('.sub-menu').hide().end().removeClass('actif--ssmenu');
    clearIntactiveTimer(this);
    ouvreMenu(this);
}).mouseleave(function() {
    setInactiveTimer(this);
});


/* -------------------
** Action quand on clique sur le bouton
** https://greensock.com/docs/Easing
** -------------------*/
$('#btRemonte').click(function(e) {
    e.preventDefault();
    gsap.to(window, { scrollTo: 0, ease: "power2.inOut", duration:1, });
});


/* -------------------
** Sidebar sticky
** https://github.com/somewebmedia/hc-sticky
** --------------------*/
$('#menu').hcSticky({
	noContainer: true
});


/* -------------------
** Sidebar sticky
** https://github.com/somewebmedia/hc-sticky
** --------------------*/
$(window).on('load resize', function(){
    var windowlarge = $(this).width();
    if (windowlarge > 996){
        var SidebarSticky = new hcSticky('#sidebar',{
            stickTo: '.contenu',
            top: 120,
            bottom: 30
        });
    }
});

/* -------------------
** Google Web font Loader
** https://github.com/typekit/webfontloader
** npm install webfontloader --save
** --------------------*/
var WebFont = require('webfontloader');
WebFont.load({
	google: {
		families: ['Roboto:700,900','Muli:300,300i,400,400i,700,700i']
	}
});

/* -------------------
** Viewport Checker
** https://github.com/dirkgroenen/jQuery-viewport-checker
** npm install jquery-plugin-viewport-checker --save
** --------------------*/
$('.apparition').viewportChecker({
    classToAdd: 'visible',
    offset: '5%',
    repeat: false
});
$('.apparition__glisse').viewportChecker({
    classToAdd: 'visible',
    offset: '10%',
    repeat: false
});

/* -------------------
** Ajoute la class sticky au header dès qu'on commence à scroller
** -------------------*/
$(window).on('load scroll resize', function() {
    var wScroll = $(this).scrollTop();
    var hautheader = $('#menu').height();
    if (wScroll > hautheader) {
        $('body').addClass('body-is-scrolling');
    } else {
        $('body').removeClass('body-is-scrolling');
    }
});

/* -------------------
** Action quand on clique sur le bouton 
** https://greensock.com/docs/Easing
** -------------------*/
if (document.getElementById("btNextPart")) {
    $('#btNextPart').click(function(e) {
        e.preventDefault();
        var nextpartcibleimage = $('#grandephoto').outerHeight();
        var nextpartcibleheader = $('#menu').outerHeight();
        var nextpartcible = nextpartcibleheader / 2 + nextpartcibleimage;
        gsap.to(window, { scrollTo: nextpartcible, duration:1, ease: "power2.inOut" });
    });
};

/* -------------------
** Parallaxe de l'image à la une
** -------------------*/
$(window).on('load scroll resize', function() {
    var titre    = $('#imagealaunepara');
    if (titre.length > 0) {
        var rapport  = $(this).scrollTop();
        var titrePos = Math.round(rapport * 0.2);
        gsap.to(titre, { y: titrePos, duration:0.2 });
    }
});

/* -------------------
** Popup du moteur de recherche
** -------------------*/
$('#btRecherche').on('click', function(e){
    e.preventDefault();
    if($('body').hasClass('recherche--on')){
      $('body').removeClass('recherche--on').addClass('recherche--off');
      setTimeout(function(){$('body').removeClass('recherche--off');},1000);
    } else {
      $('body').removeClass('recherche--off').addClass('recherche--on');
      $('#search-input').focus();
    }
});
$('#btRechercheClose').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('recherche--on').addClass('recherche--off');
    setTimeout(function(){$('body').removeClass('recherche--off');},1000);
});

/* -------------------
** Popup de la vidéo
** -------------------*/
$('#btVideo').on('click', function(e){
    e.preventDefault();
    if($('body').hasClass('video--on')){
      $('body').removeClass('video--on').addClass('video--off');
      setTimeout(function(){$('body').removeClass('video--off');},1000);
    } else {
      $('body').removeClass('video--off').addClass('video--on');
    }
});
$('#btVideoClose').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('video--on').addClass('video--off');
    setTimeout(function(){$('body').removeClass('video--off');},1000);
});

/* -------------------
** Accordéon des produits
** -------------------*/
$('.bouton-slides').on('click',function(event){
    // Désactive le click
    event.preventDefault();

    // Variables
    var ciblenom = $(this).attr('data-cible');
    var cibleroute = $(this).parent().parent().parent().parent();
    var cibledetails = cibleroute.find('.produit__details');
    var cible = cibleroute.find('.'+ ciblenom +'');
    var cibleH = cible.outerHeight();
    var ciblewrap = cibleroute.find('.produit__details__wrap');

    // Activation des class pour la slide en cours
    cibleroute.find('.produit__slide').not('.'+ ciblenom +'').removeClass('slide-visible');
    cibleroute.find('.bouton-slides').not($(this)).removeClass('ouvert');
    $(this).toggleClass('ouvert');
     
    cible.toggleClass('slide-visible');

    // Hauteur du conteneur selon la slide
    if($(this).hasClass('ouvert')){
        gsap.to(ciblewrap, {height: cibleH, marginBottom:'50px', duration:0.3});
        cibledetails.removeClass('cibleproduit__slide--details cibleproduit__slide--pro cibleproduit__slide--partage').toggleClass('cible'+ciblenom);  
    } else {
        gsap.to(ciblewrap, {height: 0, duration:0.3, marginBottom: 0 });
        cibledetails.removeClass('cibleproduit__slide--details cibleproduit__slide--pro cibleproduit__slide--partage');
    }

    // Retire le focus
    $(this).blur();
    
});

/* -------------------
** Preload de la page d'accueil
** -------------------*/
var loadedCount = 12; //current number of images loaded
var imagesToLoad = $('.bcgimg').length; //number of slides with .bcg container
var loadingProgress = 0; //timeline progress - starts at 0

$('.bcgimg').imagesLoaded({
    background: true
    }
).progress( function( instance, image ) {
    loadProgress();
});

function loadProgress(imgLoad, image)
{
    //one more image has been loaded
    loadedCount++;
    loadingProgress = (loadedCount/imagesToLoad);

    //console.log(loadingProgress);

    // GSAP timeline for our progress bar
    gsap.to(progressTl, {progress:loadingProgress, duration: 6, ease:"none"});

}

//progress animation instance. the instance's time is irrelevant, can be anything but 0 to void  immediate render
var progressTl = gsap.timeline({
    paused: true,
    onUpdate: progressUpdate,
    onComplete: loadComplete
});

progressTl
    //tween the progress bar width
    .to($('.barreprog'), {width:"100%", duration:6, ease:"none"});

//as the progress bar witdh updates and grows we put the precentage loaded in the screen
function progressUpdate()
{
    //the percentage loaded based on the tween's progress
    loadingProgress = Math.round(progressTl.progress() * 100);
    //we put the percentage in the screen
    $(".txt-perc").text(loadingProgress + '%');

}

function loadComplete() {

    // preloader out
    var preloaderOutTl = gsap.timeline();

    preloaderOutTl
    .set(document.body, {className: '-=is-loading'})
    .to(document.querySelector('.barreprog'), {backgroundColor: '#88bf57', duration: 1, ease: "power2.inOut"})
    .to(document.querySelector('.txt-perc'), {scale: 0.8, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 0.3)
    .to(document.querySelector('.barre'), {scale: 0.8, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 0.6)
    .to(document.querySelector('.txt-logo'), {scale: 0.8, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 1.2)
    .to(document.querySelector('#preloader'), {position:'absolute', autoAlpha: 0, duration: 1.6, ease: "power2.inOut"}, 1)
    .set(document.querySelector('#preloader'), {className: '+=is-hidden'})
    .from(document.querySelector('#loading-titre'), {y: 30, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 1.2)
    .from(document.querySelector('#loading-texte'), {y: 30, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 1.4)

    if ($('#loading-bouton').length) {
        preloaderOutTl.from(document.querySelector('#loading-bouton'), {y: 30, duration: 0.8, autoAlpha: 0, ease: "power2.in"}, 1.6);
    }


    // Ajouter ces lignes uniquement si les éléments existent
    if ($('.mascotte__perso').length) {
        preloaderOutTl.from('.mascotte__perso', {x: 150, duration: 1, rotation: "+=15", ease: "power2.inOut"}, 2.6);
    }

    if ($('.mascotte__bulle').length) {
        preloaderOutTl.from('.mascotte__bulle', {scale: 0.8, duration: 1, autoAlpha: 0, ease: "power2.inOut"}, 3);
    }

    return preloaderOutTl;

}

// Gestion de la musique
// https://github.com/jplayer/jPlayer
var randomnumber = Math.floor(Math.random() * 2 + 1);
var urlson;
if(randomnumber == 1){
    urlson = "https://laiterie-de-verneuil.com/wp-content/themes/verneuil/public/son/verneuil-vache.mp3";
}
if(randomnumber == 2){
    urlson = "https://laiterie-de-verneuil.com/wp-content/themes/verneuil/public/son/verneuil-chevre.mp3";
    }
    
$("#jingle").jPlayer({
    ready: function() {
        $(this).jPlayer("setMedia", {
            mp3: urlson
        }).jPlayer("play");
    },
    loop: false,
    volume: 0.5
});


// Google Map ACF Pro
/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {
	
	// var
	var $markers = $el.find('.marker');
	
	
	// vars
	var args = {
		zoom		: 16,
		center		: new google.maps.LatLng(0, 0),
        mapTypeId	: google.maps.MapTypeId.ROADMAP,
        styles:[
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#0137d0"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#dfe7ff"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#dfe7ff"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#0137d0"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#c6d4ff"
                    }
                ]
            },
            {
                "featureType": "poi.attraction",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#0137d0"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#0137d0"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "lightness": 700
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#86a5ff"
                    }
                ]
            }
        ]
	};
	
	
	// create map	        	
	var map = new google.maps.Map( $el[0], args);
	
	
	// add a markers reference
	map.markers = [];
	
	
	// add markers
	$markers.each(function(){
		
    	add_marker( $(this), map );
		
	});
	
	
	// center map
	center_map( map );
	
	
	// return
	return map;
	
}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
        map			: map,
        icon        : 'https://laiterie-de-verneuil.com/wp-content/themes/verneuil/public/assets/img/marqueur.png'
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			infowindow.open( map, marker );

		});
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;

$(document).ready(function(){

	$('.acf-map').each(function(){

		// create map
		map = new_map( $(this) );

	});

});